import { Link } from 'gatsby';
import React, { useState } from 'react'
import { Col, Container, Row, Button, Modal, NavLink, Form } from 'react-bootstrap'
import ModelPopUp from '../PopUpModel';

function Hero({ img, title, def, children, cta = 'Get a Quote', button_t = true, btnqua = true }) {
    const [modalShow, setModalShow] = useState(false);

    const handleToggle = () => {
        setModalShow(!modalShow);
    };
    return (
        <section>
            <div className='Hero mobile-app'>
                <Container>
                    <Row className='align-items-center justify-content-center'>
                        <Col xl={6} md={12} className=" order-xl-1 order-2">
                            <div className="hero-content ">
                                <h1 data-aos="zoom-in">{title}</h1>
                                <p data-aos="fade-right">{def}</p>
                                <>
                                    {
                                        btnqua ?
                                            <>
                                                {
                                                    button_t ? <>
                                                        <Link to="/quotation" className='hero-btn btn-white-color btn-animate ' >
                                                            {cta}
                                                        </Link>
                                                    </>
                                                        :
                                                        <>
                                                            <NavLink href="#opening" className='hero-btn btn-white-color btn-animate ' >
                                                                {cta}
                                                            </NavLink>
                                                        </>
                                                }

                                            </>
                                            : ''
                                    }
                                </>
                            </div>
                        </Col>
                        <Col xl={6} md={12} className="order-xl-2  order-1">
                            <div className='updown' data-aos="zoom-in">
                                {children}
                            </div>
                        </Col>
                    </Row>

                </Container>

            </div >
            <ModelPopUp showVal={modalShow} />
        </section>
    )
}

export default Hero