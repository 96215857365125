import React from 'react'
import { Card, Col, Container, Row } from 'react-bootstrap'

function Vision() {
    return (
        <div className='vision'>
            <Container>
                <Row className='justify-content-center mt-lg-4 pt-lg-2 pb-lg-5'>
                    <Col lg={6}>
                        <Card data-aos="zoom-out-right" className='me-lg-2 me-xl-3'>
                            <div className="card-content" >
                                <div className='d-flex align-items-center'>
                                    <img src="../assets/img/about/vision_img/our-vision.gif" className='img-fluid' alt="our-vision" />
                                    <h2> Vision</h2>
                                </div>
                                <p>To become the leading IT Company who empowers people for their growth and happiness through the power of digital services.</p>
                            </div>

                        </Card>
                    </Col>
                    <Col lg={6} className="mt-4 mt-lg-0" >
                        <Card data-aos="zoom-out-left" className='ms-lg-2 ms-xl-3'>
                            <div className="card-content" >
                                <div className='d-flex align-items-center'>
                                    <img src="../assets/img/about/vision_img/our-mission.gif" className='img-fluid' alt="our-mission" />
                                    <h2> Mission</h2>
                                </div>
                                <p>
                                    <ul className="ps-4">
                                        <li>Understand and analyze clients requirements and their business.</li>
                                        <li>Provide world-class standard professional services to the employees and customers.</li>
                                        <li>Enhance clients business growth digitally.</li>
                                        <li>Make product (Application / Game) which is useful for everyone worldwide.</li>
                                    </ul>
                                </p>
                            </div>

                        </Card>
                    </Col>
                </Row>

                <Row className='mt-5 pt-0 mt-md-5'>
                    <Col sm={6} className="our-value our-value-x d-flex align-items-center justify-content-center">
                        <div className='text-center value-box' data-aos="zoom-out-up">
                            <div className='img-circle'>
                                <img src="../assets/img/about/vision_img/growth.gif" alt="growth" className='wh-auto' width="130" height="130" />
                            </div>
                            <h3>Growth</h3>
                        </div>
                    </Col>
                    <Col sm={6} className="our-value our-value-x  d-flex  align-items-center justify-content-center">
                        <div className='text-center value-box' data-aos="zoom-out-down">
                            <div className='img-circle'>
                                <img src="../assets/img/about/vision_img/passion.gif" alt="passion" className='wh-auto' width="130" height="130" />
                            </div>
                            <h3>Passion</h3>
                        </div>
                    </Col>

                    <Col md={4} sm={6} className="our-value-box2 our-value our-value-x our-value-margin box-order-2">
                        <div className='value-box me-auto' data-aos="zoom-out-up">
                            <div className='img-circle'>
                                <img src="../assets/img/about/vision_img/quality.gif" alt="quality" className='wh-auto' width="130" height="130" />
                            </div>
                            <h3>Quality</h3>
                        </div>
                    </Col>

                    <Col md={4} className="our-value-box2 our-value d-flex align-items-center justify-content-center  our-value-margin">
                        <div className="sep-border">
                            <div className="big-circle-box" data-aos="zoom-in-down">
                                <h2>Our</h2>
                                <h2>Core Values</h2>
                            </div>
                        </div>
                    </Col>

                    <Col md={4} sm={6} className="our-value-box2  our-value-x our-value our-value-margin">
                        <div className='value-box ms-md-auto' data-aos="zoom-out-up">
                            <div className='img-circle'>
                                <img src="../assets/img/about/vision_img/happiness.gif" alt="happiness" className='wh-auto' width="130" height="130" />
                            </div>
                            <h3>Happiness</h3>
                        </div>
                    </Col>



                </Row>

            </Container>
        </div >
    )
}

export default Vision
