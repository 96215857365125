
import React from 'react'
import { Card, Col, Container, Row } from 'react-bootstrap'

function OurExpertise() {
    return (
        <div className='our-expertise'>
            <Container>
                <div className="heading">
                    <h2>Our Pillars of Strength</h2>
                </div>
                <Row className='mt-5 pt-0 pt-lg-3 d-flex justify-content-center'>
                    <div className="mb-4 w-auto">
                        <Card data-aos="zoom-in-down">
                            <img src="../assets/img/about/ourexpertise/Technology.gif" className='img-fluid wh-auto' alt="technology" width="140" height="140" />
                            <h4>Technology</h4>
                        </Card>
                    </div>
                    <div className="mb-4 w-auto"  >
                        <Card data-aos="zoom-in-up">
                            <img src="../assets/img/about/ourexpertise/Collaboration.gif" className='img-fluid wh-auto' alt="collaboration" width="140" height="140"/>
                            <h4>Collaboration</h4>
                        </Card>
                    </div>
                    <div className="mb-4 w-auto">
                        <Card data-aos="zoom-out-down">
                            <img src="../assets/img/about/ourexpertise/Adaptability.gif" className='img-fluid wh-auto' alt="adaptability" width="140" height="140"/>
                            <h4>Adaptability</h4>
                        </Card>
                    </div>
                    <div className="mb-4 w-auto">
                        <Card data-aos="zoom-in-up" >
                            <img src="../assets/img/about/ourexpertise/Persistance.gif" className='img-fluid wh-auto' alt="persistance" width="140" height="140"/>
                            <h4>Persistence</h4>
                        </Card>
                    </div>
                    <div className="mb-4 w-auto">
                        <Card data-aos="zoom-in-down">
                            <img src="../assets/img/about/ourexpertise/User-Experience.gif" className='img-fluid wh-auto' alt="experience" width="140" height="140"/>
                            <h4>User Experience</h4>
                        </Card>
                    </div>
                    <div className="mb-4 w-auto">
                        <Card data-aos="zoom-in-up">
                            <img src="../assets/img/about/ourexpertise/Development.gif" className='img-fluid wh-auto' alt="development" width="140" height="140"/>
                            <h4>Development</h4>
                        </Card>
                    </div>

                </Row>
            </Container>
        </div>
    )
}

export default OurExpertise
