
import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'

function ExpertTeam({ year_of_experience, expert_team, global_client, projects_accomplished }) {
    return (
        <div className='expert'>

            <Container>
                <Row className='justify-content-center align-items-center'>
                    <Col lg={4} md={12} className="mt-5" >

                        <Row className='justify-content-center'>
                            <Col lg={12} md={6}>
                                <div className="expert-card-box d-flex justify-content-center align-items-center" data-aos="zoom-out-right">
                                    <img src="../assets/img/about/expertimg/upwork-top-rated.svg" alt="upwork-top-rated" />
                                    <div className="ex-content">
                                        <h2>Upwork </h2>
                                        <h2> Top Rated+</h2>
                                    </div>
                                </div>
                            </Col>
                            <Col lg={12} md={6}>
                                <div className="expert-card-box d-flex justify-content-center align-items-center color-red " data-aos="zoom-out-left">
                                    <img src="../assets/img/about/expertimg/preferred-freelancer.svg" alt="preferred-freelancer" className='img-preferred' />
                                    <div className="ex-content">
                                        <h2>Preferred </h2>
                                        <h2> Freelancer</h2>
                                    </div>
                                </div>
                            </Col>
                            <Col lg={12} md={6}>
                                <div className="expert-card-box d-flex justify-content-center align-items-center color-green" data-aos="zoom-out-right">
                                    <img src="../assets/img/about/expertimg/eight-years-of-experience.svg" alt="eight-years-of-experience" />
                                    <div className="ex-content">
                                        <h2>{year_of_experience}+ Years of

                                        </h2>
                                        <h2>Industry</h2>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Col>

                    <Col lg={4}>
                        <div className="center-img" data-aos="zoom-in-down">
                            <img src="../assets/img/about/expertimg/read-our-review.svg" className='img-fluid wh-auto' alt="read-our-review" width="487" height="550"/>
                        </div>
                    </Col>


                    <Col lg={4} md={12} className="mt-5">

                        <Row className='justify-content-center'>
                            <Col lg={12} md={6}>
                                <div className="expert-card-box d-flex justify-content-center  align-items-center color-yellow " data-aos="zoom-out-left">
                                    <img src="../assets/img/about/expertimg/two-x-growth-rate.svg" alt="two-x-growth-rate" />
                                    <div className="ex-content">
                                        <h2>2x Growth Rate </h2>
                                    </div>
                                </div>
                            </Col>
                            <Col lg={12} md={6}>
                                <div className="expert-card-box d-flex justify-content-center align-items-center color-pink" data-aos="zoom-out-right">
                                    <img src="../assets/img/about/expertimg/expert-team.svg" alt="expert-team" className='img-expert' />
                                    <div className="ex-content">
                                        <h2>{expert_team}+ Experts
                                        </h2>
                                        <h2>  Team</h2>
                                    </div>
                                </div>
                            </Col>
                            <Col lg={12} md={6}>
                                <div className="expert-card-box d-flex justify-content-center align-items-center color-last " data-aos="zoom-out-left">
                                    <img src="../assets/img/about/expertimg/project-accomplished.svg" alt="project-accomplished" />
                                    <div className="ex-content">
                                        <h2>{projects_accomplished}+ Projects
                                        </h2>
                                        <h2>  Accomplished</h2>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Col>

                </Row>
            </Container>

        </div>
    )
}

export default ExpertTeam
