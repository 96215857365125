import React from 'react'
import Slider from "react-slick";
function LifeVasundhara() {


    const settings = {
        dots: false,
        pauseOnHover: false,
        pauseOnFocus: false,
        infinite: true,
        speed: 1000,
        autoplaySpeed: 5000,
        slidesToShow: 5,
        slidesToScroll: 1,
        adaptiveHeight: true,
        centerMode: false,
        autoplay: true,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 4,
                    centerMode: true,
                }
            },
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 3,
                }
            },
            {
                breakpoint: 640,
                settings: {
                    slidesToShow: 2,
                }
            },
            {
                breakpoint: 375,
                settings: {
                    slidesToShow: 1,
                }
            }
        ]
    };
    const settings1 = {
        dots: false,
        rtl: true,
        pauseOnHover: false,
        pauseOnFocus: false,
        infinite: true,
        speed: 1000,
        autoplaySpeed: 5000,
        slidesToShow: 5,
        slidesToScroll: 1,
        adaptiveHeight: true,
        centerMode: false,
        autoplay: true,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 4,
                    centerMode: true,
                }
            },
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 3,
                }
            },
            {
                breakpoint: 640,
                settings: {
                    slidesToShow: 2,
                }
            },
            {
                breakpoint: 375,
                settings: {
                    slidesToShow: 1,
                }
            }
        ]
    };
    return (
        <div className="lifevasundhara pb-100 pt-lg-0 pt-3">
            <div className='heading text-center mb-5'>
                <h2>Life At Vasundhara</h2>
            </div>



            <div className='mt-5 pt-sm-3 life-vasu-slider'>
                <Slider {...settings}>
                    <div className="mx-2 vasu-life-box">
                        <img src="../assets/img/about/Lifeslider/1.svg" alt="lifeslider-2" className='img-fluid wh-auto' width="366" height="366" />
                    </div>
                    <div className="mx-2 vasu-life-box">
                        <img src="../assets/img/about/Lifeslider/2.svg" alt="lifeslider-1" className='img-fluid wh-auto' width="366" height="366" />
                    </div>
                    <div className="mx-2 vasu-life-box">
                        <img src="../assets/img/about/Lifeslider/3.svg" alt="lifeslider-2" className='img-fluid wh-auto' width="366" height="366" />
                    </div>
                    <div className="mx-2 vasu-life-box">
                        <img src="../assets/img/about/Lifeslider/4.svg" alt="lifeslider-5" className='img-fluid wh-auto' width="366" height="366" />
                    </div>
                    <div className="mx-2 vasu-life-box">
                        <img src="../assets/img/about/Lifeslider/5.svg" alt="lifeslider-2" className='img-fluid wh-auto' width="366" height="366" />
                    </div>
                    <div className="mx-2 vasu-life-box">
                        <img src="../assets/img/about/Lifeslider/1.svg" alt="lifeslider-2" className='img-fluid wh-auto' width="366" height="366" />
                    </div>
                    <div className="mx-2 vasu-life-box">
                        <img src="../assets/img/about/Lifeslider/2.svg" alt="lifeslider-1" className='img-fluid wh-auto' width="366" height="366" />
                    </div>
                    <div className="mx-2 vasu-life-box">
                        <img src="../assets/img/about/Lifeslider/3.svg" alt="lifeslider-2" className='img-fluid wh-auto' width="366" height="366" />
                    </div>
                    <div className="mx-2 vasu-life-box">
                        <img src="../assets/img/about/Lifeslider/4.svg" alt="lifeslider-5" className='img-fluid wh-auto' width="366" height="366" />
                    </div>
                    <div className="mx-2 vasu-life-box">
                        <img src="../assets/img/about/Lifeslider/5.svg" alt="lifeslider-2" className='img-fluid wh-auto' width="366" height="366" />
                    </div>
                </Slider>
            </div>



            <div className='mt-3 mb-sm-5 life-vasu-slider'>

                <Slider {...settings1}>

                    <div className="mx-2 vasu-life-box">
                        <img src="../assets/img/about/Lifeslider/6.svg" alt="lifeslider-2" className='img-fluid wh-auto' width="366" height="366" />
                    </div>

                    <div className="mx-2 vasu-life-box">
                        <img src="../assets/img/about/Lifeslider/7.svg" alt="lifeslider-1" className='img-fluid wh-auto' width="366" height="366" />
                    </div>

                    <div className="mx-2 vasu-life-box">
                        <img src="../assets/img/about/Lifeslider/8.svg" alt="lifeslider-2" className='img-fluid wh-auto' width="366" height="366" />
                    </div>

                    <div className="mx-2 vasu-life-box">
                        <img src="../assets/img/about/Lifeslider/9.svg" alt="lifeslider-5" className='img-fluid wh-auto' width="366" height="366" />
                    </div>

                    <div className="mx-2 vasu-life-box">
                        <img src="../assets/img/about/Lifeslider/10.svg" alt="lifeslider-2" className='img-fluid wh-auto' width="366" height="366" />
                    </div>

                    <div className="mx-2 vasu-life-box">
                        <img src="../assets/img/about/Lifeslider/6.svg" alt="lifeslider-2" className='img-fluid wh-auto' width="366" height="366" />
                    </div>

                    <div className="mx-2 vasu-life-box">
                        <img src="../assets/img/about/Lifeslider/7.svg" alt="lifeslider-1" className='img-fluid wh-auto' width="366" height="366" />
                    </div>

                    <div className="mx-2 vasu-life-box">
                        <img src="../assets/img/about/Lifeslider/8.svg" alt="lifeslider-2" className='img-fluid wh-auto' width="366" height="366" />
                    </div>

                    <div className="mx-2 vasu-life-box">
                        <img src="../assets/img/about/Lifeslider/9.svg" alt="lifeslider-5" className='img-fluid wh-auto' width="366" height="366" />
                    </div>

                    <div className="mx-2 vasu-life-box">
                        <img src="../assets/img/about/Lifeslider/10.svg" alt="lifeslider-2" className='img-fluid wh-auto' width="366" height="366" />
                    </div>

                </Slider>
            </div>
        </div>
    )
}





export default LifeVasundhara


