import React, { useEffect, useState } from 'react'
import { Container } from 'react-bootstrap'
import allApidata from '../../api';
function CompanyHistory() {
    const [gethistoryData, setHistoryData] = useState([]);
    useEffect(() => {
        allApidata.compnayHistory().then((data) => {
            setHistoryData(data.data);
        })
    }, [])
    return (
        <div className='history'>
            <Container>
                <div className="text-center ">
                    <div className="history-heading">
                        <h2>Our Journey So Far</h2>
                    </div>
                </div>

                <div className="main-line">
                    <div className="timeline">

                        {
                            gethistoryData && gethistoryData.map((history, i) => {

                                let even = i % 2 === 0;
                                return (

                                    <>
                                        {
                                            even ?
                                                <>
                                                    <div key={i} className="row">
                                                        <div className="history-box">
                                                            <h2>{history.key}</h2>
                                                        </div>

                                                        <div className="history-box history-content ">
                                                            {/* <h3>{history['value'][0].title}</h3> */}
                                                            <p className='pt-0'>{history['value'][0].description}</p>
                                                        </div>
                                                    </div>
                                                </> :
                                                <>
                                                    <div key={i} className="row">
                                                        <div className="history-box history-content ">
                                                            {/* <h3>{history['value'][0].title}</h3> */}
                                                            <p className='pt-0'>{history['value'][0].description}</p>
                                                        </div>
                                                        <div className="history-box">
                                                            <h2>{history.key}</h2>
                                                        </div>
                                                    </div>
                                                </>
                                        }
                                    </>
                                )
                            })
                        }
                    </div>
                </div>
            </Container>
        </div>
    )
}

export default CompanyHistory