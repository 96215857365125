import React, { useEffect, useState } from 'react'
import Printing from '../components/About/Printing'
import CompanyHistory from '../components/About/CompanyHistory'
import Vision from '../components/About/Vision'
import ExpertTeam from '../components/About/ExpertTeam'
import OurExpertise from '../components/About/OurExpertise';
import allApiData from '../api';
import Hero from '../components/Mobileapp/hero';
import Layout from '../components/Layout';
import Seo from '../components/Seo';
import Seodata from '../siteScriptData';

import LifeVasundhara from '../components/About/LifeVasundhara';

import Vasundharaimg from '../assets/img/about/hero_img/vasundhara.svg'
import Vasundharaimg1 from "../assets/img/about/hero_img/1.svg"
import Vasundharaimg2 from "../assets/img/about/hero_img/2.svg"
import Vasundharaimg3 from "../assets/img/about/hero_img/3.svg"
import Vasundharamobile from "../assets/img/about/hero_img/vasundhara-mobile.svg"

function About() {
    const [alldata, setAlldata] = useState([]);
    const { year_of_experience, expert_team, projects_accomplished } = alldata;
    allApiData.removeanimation();
    useEffect(() => {
        allApiData.aosinit()
    }, []);

    useEffect(() => {
        allApiData.getHomeApi().then((data) => {
            setAlldata(data.footer_settings)
        })
    }, [])

    return (
        <div>
            <Layout>
                <Seo SeoData={Seodata.aboutusPageSeoData}></Seo>
                <div className='about'>
                    <Hero title="About Vasundhara" def="Serving customers with the latest tech-infused software solutions, our innovation is what sets us apart from others. We are your partners in technical transformation."
                        cta='Get a Quote'>
                        <div className="hero-img about-height  hide-animation-768 my-lg-5">
                            <img src={Vasundharaimg} className="wh-auto img-fluid height-650" alt="about vasundhara" width="743" height="574"/>
                            <img src={Vasundharaimg1} className="img-fluid  about-img-one" alt="about vasundhara-1" />
                            <img src={Vasundharaimg2} className="img-fluid  about-img-two" alt="about vasundhara-2" />
                            <img src={Vasundharaimg3} className="img-fluid  about-img-three" alt="about vasundhara-3" />
                        </div>
                        <div className="show-main-img-768">
                            <img src={Vasundharamobile} className="img-fluid height-650 wh-auto" alt="vasundhara-mobile" width="680" height="525" />
                        </div>
                    </Hero>
                    <Printing />
                    <CompanyHistory />
                    <Vision />
                    <OurExpertise />
                    <ExpertTeam year_of_experience={year_of_experience} expert_team={expert_team} projects_accomplished={projects_accomplished} />
                    <LifeVasundhara />
                </div>
            </Layout>
        </div>
    )
}

export default About