import React from 'react'
import { Col, Container, Row, } from 'react-bootstrap';

import Whoweareimg from "../../assets/img/about/printing_img/who-we-are.svg"
import Whoweareimg2 from "../../assets/img/about/printing_img/2.svg"
import Whoweareimg3 from "../../assets/img/about/printing_img/3.svg"
import Whoweareimg4 from "../../assets/img/about/printing_img/4.svg"
import WhoweareMobileimg from "../../assets/img/about/printing_img/who-we-are-mobile.svg"


function Printing() {
    return (
        <div className='margin-2'>
            <div className=" about-printing" id="">
                <Container>
                    <Row className='align-items-center  justify-content-between'>
                        <Col lg={6} md={12} className="order-lg-1 order-2 "  >
                            <div className=" printing-img hero-img  hide-animation-768  " data-aos="zoom-in" >
                                <img src={Whoweareimg} className="z-index img-fluid width-450 wh-auto" alt="who-we-are" width="680" height="500" />
                                <img src={Whoweareimg2} className="img-fluid print-img-two" alt="who-we-are-1" />
                                <img src={Whoweareimg3} className="img-fluid print-img-three" alt="who-we-are-2" />
                                <img src={Whoweareimg4} className="img-fluid print-img-four" alt="who-we-are-3" />
                            </div>

                            <div className="show-main-img-768">
                                <img src={WhoweareMobileimg} className="img-fluid height-650 wh-auto" alt="who-we-are-mobile" width="680" height="500" />
                            </div>
                        </Col>
                        <Col lg={6} md={12} className="order-lg-1 order-2">
                            <div className="about-printing-content  " data-aos="zoom-in">
                                <div className="heading">
                                    <h2>Who We Are</h2>
                                </div>
                                <p>Vasundhara is one of the most advanced software solutions providers in the industry. We have been delivering high quality services and world class technology solutions to customers since 2013. As a part of the software development industry, we are into 4 major verticals, viz - mobile app development, game development, web development and NFT designs along with providing offshore development teams. </p>
                                <p>Our team of 300+ experienced professionals comprises software developers, UI/UX designers, quality analysts, technology experts and the R&D team. The capabilities and knowledge they bring enables us to deliver complex projects on time.</p>
                                <p>What we bring to you is creativity, understanding, and expertise which ensures that the results delivered align with your vision and objectives. </p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    )
}

export default Printing